import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 1,
  class: "text-white font-bold text-xl"
}
const _hoisted_3 = { class: "w-10 h-10 absolute left-0 right-0 top-0 bottom-0 m-auto flex" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadImg = _resolveComponent("HeadImg")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", {
      class: ["relative h-14 flex items-center justify-between p-4", { 'bg-neutral-08': _ctx.showBackground }]
    }, [
      _createVNode("div", _hoisted_1, [
        (_ctx.showBack && (_ctx.backPath || _ctx.canBack))
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
            }, [
              _createVNode("img", {
                class: "w-6 h-6 mr-4",
                src: _ctx.back,
                alt: "back"
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.title)
          ? (_openBlock(), _createBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_3, [
        (_ctx.header)
          ? (_openBlock(), _createBlock(_component_HeadImg, {
              key: 0,
              class: "absolute left-0 right-0 top-0 bottom-0 m-auto",
              size: 40,
              img: _ctx.header
            }, null, 8, ["img"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showRight || _ctx.rightIcon)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode("img", {
              class: "w-6 h-6",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clickRight'))),
              src: _ctx.rightIcon || _ctx.sort,
              alt: ""
            }, null, 8, ["src"])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}