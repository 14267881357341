
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import back from "@/assets/nfts/back.webp";
import sort from "@/assets/nfts/sort.webp";
import HeadImg from "@/components/commons/HeadImg.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Navagation",
  components: {
    HeadImg,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    backPath: {
      type: String,
      default: "",
    },
    showRight: {
      type: Boolean,
      default: false,
    },
    rightIcon: {
      type: Object,
    },
    header: {
      type: Object,
    },
  },
  setup(props) {
    const canBack = history.length > 1;
    const goBack = () => {
      if (history.length > 1) {
        history.go(-1);
      } else if (props.backPath) {
        location.href = props.backPath;
      }
    };
    return {
      back,
      sort,
      goBack,
      canBack,
    };
  },
});
