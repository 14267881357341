
import { defineComponent, ref } from "vue";
import Navagation from "@/components/commons/Navagation.vue";
import Page from "@/components/commons/Page.vue";
import sort from "@/assets/nfts/sort.webp";

import { useRoute } from "vue-router";
import { getTxsAccountWallet } from "@/model/nftLanding";
import { getLongStringShow } from "@/utils/formatString";
import { displayDateFormat } from "@/utils/formatTime";
import {
  kmbNumber,
  getFloat,
  formatPercentageValue,
} from "@/utils/formatNumber";
import { getQueryString } from "@/utils/url";
import { modifyImgSize } from "@/utils/imgSize";
import { useLog } from "@/utils/log";

export default defineComponent({
  name: "ItemsDetail",
  components: {
    Navagation,
    Page,
  },
  setup() {
    const state: any = ref({});
    const states: any = ref([]);
    const hadEnd = ref(false);
    const hadData = ref(false);
    const pageNum = ref(0);
    const totalNumber = ref(8);
    const selectIndex = ref(0);
    const route = useRoute();
    const { handleClickLog } = useLog();
    const getTxsAccountWalletData = (first = false) => {
      getTxsAccountWallet({
        txHash: route.params.txHash,
        accountAddr: route.params.accountAddr,
        transferType: route.query.transferType,
        contract: route.query.contract,
        pageNum: pageNum.value,
        pageSize: 8,
      }).then((res) => {
        hadData.value = true;
        if (res?.data?.length) {
          totalNumber.value = res?.data?.length;
          states.value = states.value.concat(res?.data || []);
          state.value = states.value[selectIndex.value];
        } else {
          hadEnd.value = true;
        }
      });
    };
    getTxsAccountWalletData(true);
    const start = { x: 0, y: 0 };
    const end = { x: 0, y: 0 };
    const handleTouchstart = (e) => {
      start.x = e.touches[0].pageX;
      start.y = e.touches[0].pageY;
    };
    const handleTouchend = (e) => {
      end.x = e.changedTouches[0].pageX;
      end.y = e.changedTouches[0].pageY;
      if (
        Math.abs(end.x - start.x) <= Math.abs(end.y - start.y) ||
        Math.abs(end.x - start.x) < 20
      )
        return;
      if (start.x > end.x) {
        if (selectIndex.value < states.value.length - 1) {
          selectIndex.value += 1;
          if (selectIndex.value === states.value.length - 1 && !hadEnd.value) {
            pageNum.value += 1;
            getTxsAccountWalletData();
          }
        }
      } else {
        if (selectIndex.value > 0) selectIndex.value -= 1;
      }
      state.value = states.value[selectIndex.value];
    };
    const getHref = () => {
      return state.value?.belongToCollection?.id
        ? `/collection-detail/${state.value?.contract}?${getQueryString([
            "contract",
          ])}`
        : "javascript:;";
    };
    return {
      sort,
      state,
      states,
      hadData,
      getLongStringShow,
      kmbNumber,
      getFloat,
      formatPercentageValue,
      displayDateFormat,
      handleClickLog,
      getQueryString,
      modifyImgSize,
      getHref,
      selectIndex,
      totalNumber,
      handleTouchstart,
      handleTouchend,
    };
  },
});
