/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { onUpdated } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { lang } from "@/locales";
interface SessionParams {
  country?: string;
  language?: string;
  product?: string;
  screen_height?: number;
  screen_width?: number;
  [props: string]: any;
}
interface EventParams {
  event_type: string;
  news_entry_id?: string;
  origin?: string;
  category?: string;
  news_type?: string;
  request_id?: string;
  labels?: string;
  ts?: number;
  [props: string]: any;
}
interface Params {
  log_type?: string;
  session_params?: SessionParams;
  events?: Array<EventParams>;
  [props: string]: any;
}

// Hooks about log
const useLog = () => {
  const { getters } = useStore();
  const route = useRoute();
  const app_version = getters.appVersion;
  const news_device_id = getters.uid;
  const domain = "https://news-af-2.feednews.com" || "";
  const ua = navigator.userAgent;
  const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const product = route.query?.origin === "iframe" ? "desktop-crypto-wallet" : isiOS ? "crypto-browser-ios" : "crypto-browser-android";
  const screen_height = window.screen.height;
  const screen_width = window.screen.width;
  // string | LocationQueryValue[]
  const country = route.query?.country || "global";
  const lowerCountry = typeof country === "string" ? country.toLowerCase() : country[country.length - 1]?.toLowerCase();
  const cbCountry = "cb";
  const lowerLang = lang;

  // send log path
  const logUrl = (country = cbCountry, language = lowerLang, log_type = "log_session_events", time: number, product = "crypto-browser-android") => `${domain}/${country}/${language}/${log_type}?product=${product}&rts=${time}`;

  // Method of sending record data request
  const sendLog = (logUrl: string, params: string) => {
    if (navigator.sendBeacon) {
      const res = navigator.sendBeacon(logUrl, params);
      if (!res) {
        navigator.sendBeacon(logUrl, params)
      }
    } else {
      const createXHR = () => {
        if (window.XMLHttpRequest) {
          return new XMLHttpRequest();
        }
        return new ActiveXObject("Microsoft.XMLHTTP");
      }
      const xhr = createXHR();
      xhr.open("POST", logUrl, true);
      xhr.send(params);
      xhr.onreadystatechange = function () {
        if (xhr.status !== 200) {
          xhr.send(params);
        }
      };
    }
  };

  const log = (params: Params) => {
    // /* Process the parameters required by the request */
    const datas: Params = { country: cbCountry, language: lowerLang, app_version, news_device_id, product, ...params };
    const ts: number = Math.floor(new Date().getTime() / 1000);
    const url = logUrl(datas.country, datas.language, datas.log_type, ts, datas.product);

    if (datas?.log_type === "log_session_info") {
      datas.session_params = params?.session_params || {};
      datas.session_params.country = datas?.country?.toUpperCase() || "GLOBAL";
      datas.session_params.real_nation = lowerCountry;
      datas.session_params.product = product;
      datas.session_params.app_version = app_version;
      datas.session_params.ts = ts;
      datas.session_params.news_device_id = news_device_id;
      datas.session_params.screen_width = screen_width;
      datas.session_params.screen_height = screen_height;
      delete datas?.product;
      delete datas?.app_version;
      delete datas?.news_device_id;
    }
    if (datas?.events?.[0]) {
      datas.events[0].ts = ts;
      if (datas.events[0]?.news_entry_id) datas.events[0].news_entry_id += "";
      datas.events[0].extra = JSON.stringify({ "real_nation": lowerCountry });
    }
    if (datas?.clip_events?.[0]) {
      datas.clip_events[0].ts = ts;
      if (datas.clip_events[0]?.news_entry_id) datas.clip_events[0].news_entry_id += "";
      datas.clip_events[0].extra = JSON.stringify({ "real_nation": lowerCountry });
    }
    delete datas?.log_type;
    // hidden  log
    // sendLog(url, JSON.stringify(datas));
  };

  // The card is clicked
  const handleClickLog = (data: EventParams, isClip = false) => {
    if (isClip) {
      log({
        clip_events: [
          data
        ]
      });
    } else {
      log({
        events: [
          data
        ]
      });
    }
  };

  const doms: any = [], hrefs: string[] = [], logKeys: string[] = [];
  const itemRef = (el: HTMLElement) => {
    if (el) {
      const href = el.attributes["href"].value || "";
      if (hrefs.indexOf(href) === -1) {
        doms.push(el);
        hrefs.push(href);
      }
    }
  };

  // The card is displayed on the screen
  const showLog = (data: any, params: EventParams, log_key_id: string) => {
    const { event_type = "", origin = "", category = "", news_entry_id = "", news_type = "", request_id = "", labels = "" } = params;
    onUpdated(() => {
      const domArray = doms;
      domArray.forEach((el, index) => {
        const ob = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              const item: any = category === "nfts" ? data.value[index].latestTx : data.value[index];
              if (logKeys.indexOf(item[log_key_id]) === -1) {
                logKeys.push(item[log_key_id]);
                log({
                  events: [
                    {
                      event_type,
                      origin:
                        category === "multimedia" ?
                          item[origin] === 'clip' ? 'corner_video' : 'corner_podcast'
                          : origin,
                      category: item?.[category] || category,
                      news_entry_id: item?.[news_entry_id] || news_entry_id,
                      news_type: item?.[news_type] || news_type,
                      request_id: item?.[request_id] || request_id,
                      labels:
                        category === "multimedia" ?
                          item[origin] === 'clip' ? 'video' : 'podcast'
                          : category === "calendar" ? item.label.toLowerCase()
                            : labels,
                      infra_feedback: item?.infra_feedback,
                    }
                  ]
                });
              }
            }
          });
        ob.observe(el as HTMLElement);
      });
    });
  };

  return {
    log,
    handleClickLog,
    itemRef,
    showLog,
    product
  }
}

export {
  useLog
}
