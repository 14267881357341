/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { get } from "@/api/http";
import {
  collections,
  transactions,
  tags,
  collectionDetail,
  itemDetail,
  txsAccountWallet,
  item,
} from "@/api/api";
import { successCallback, errorCallback } from "@/scripts/constants"
import store from "@/store"

const getCollections = (params?: any) => {
  return get(collections, { ...params }).then(successCallback).catch(errorCallback)
};

const getTransactions = (params?: any) => {
  return get(transactions, { ...params }).then(successCallback).catch(errorCallback)
};

const getTags = (params?: any) => {
  return get(tags, { ...params }).then(successCallback).catch(errorCallback)
};

const getCollectionDetail = (params?: any) => {
  return get(collectionDetail, { ...params }).then(successCallback).catch(errorCallback)
};

const getItemDetail = (params?: any) => {
  return get(itemDetail, { ...params }).then(successCallback).catch(errorCallback)
};

const getTxsAccountWallet = (params?: any) => {
  return get(txsAccountWallet(params?.txHash, params?.accountAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getItem = (params?: any) => {
  return get(item, { ...params }).then(successCallback).catch(errorCallback)
};

export {
  getCollections,
  getTransactions,
  getTags,
  getCollectionDetail,
  getItemDetail,
  getTxsAccountWallet,
  getItem,
}
