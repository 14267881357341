import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center h-5 pl-2 bg-black-30 rounded-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.totalNumber, (it, index) => {
      return (_openBlock(), _createBlock("div", {
        class: ["w-1.5 h-1.5 rounded-50 mr-2", {
        'bg-primary-100': index == _ctx.selectedIndex,
        'bg-whites-40': index != _ctx.selectedIndex,
      }],
        key: index
      }, null, 2))
    }), 128))
  ]))
}