
import { defineComponent } from "vue";

export default defineComponent({
  name: "Page",
  props: {
    totalNumber: {
      type: Number,
      default: 8,
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
  }
});
